function getVals(){
  // Get slider values
  var parent = this.parentNode;
  console.log(parent);
  var slides = parent.getElementsByTagName("input");
    var slide1 = parseFloat( slides[0].value );
    var slide2 = parseFloat( slides[1].value );

    // var slide3 = parseFloat( slides[2].value );
    // var slide4 = parseFloat( slides[3].value );
    // console.log(slide1);
    // console.log(slide2);
    // console.log(slide3);
    // console.log(slide4);
  // Neither slider will clip the other, so make sure we determine which is larger
  if( slide1 > slide2 ){ var tmp = slide2; slide2 = slide1; slide1 = tmp; }
  
  var displayElement = parent.getElementsByClassName("bs-laminat__min")[0],
      displayElement2 = parent.getElementsByClassName("bs-laminat__max")[0];
      displayElement.innerHTML = slide1;
      displayElement2.innerHTML = slide2;
}

window.onload = function(){
  // Initialize Sliders
  var sliderSections = document.getElementsByClassName("range-slider");
      for( var x = 0; x < sliderSections.length; x++ ){
        var sliders = sliderSections[x].getElementsByTagName("input");
        for( var y = 0; y < sliders.length; y++ ){
          if( sliders[y].type ==="range" ){
            sliders[y].oninput = getVals;
            // Manually trigger event first time to display values
            sliders[y].oninput();
          }
        }
      }
}

