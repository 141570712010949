(function ($) {
    'use strict';
    $(document).ready(function () {

        var bsWrap = $('#bs-wrapper');
        var bodyH = $('body').outerHeight(true);
        var windowH = $(window).height();
        if(bodyH < windowH){
            var headerH = $('#bs-header').outerHeight(true);
            var footerH = $('#bs-footer').outerHeight(true);
            var bsWrapH = windowH - (headerH + footerH);
            bsWrap.css('min-height', bsWrapH);
        }

    });
} (jQuery));