$('.one-time').slick({
  dots: true,
  infinite: true,
  speed: 700,
  slidesToShow: 1,
  adaptiveHeight: true,
  autoplay: true,
  arrows: false
});

$('.brands').slick({
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  dots: false,
  autoplay: true,
  speed: 700,
  autoplaySpeed: 1500,
  arrows: true
});

$('.hits').slick({
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: false,
  speed: 700,
  arrows: true
});

$('.slider-for').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor: '.slider-nav'
});
$('.slider-nav').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '.slider-for',
  dots: false,
  centerMode: false,
  focusOnSelect: true
});
